import React from "react";

export default function(props) {
  return (
    <main>
      <section>
        <h1 className="teal">Healthcare, informed by genetics</h1>
        <p>
          This tool provides aggregated genetic results, demographics, and
          classifications for all the individuals who have been tested at
          Invitae. Only variants in clinically requisitioned genes are available
          in this tool and it is updated on a bi-monthly basis.
        </p>
      </section>
      <section className="with-icon lower">
        <div className="icon">
          <img
            src="/assets/handshake-heart.svg"
            alt="heart-shaped handshake svg icon"
          />
        </div>
        <div className="text">
          <h2>Open Science</h2>
          <p>
            Sharing genetic and other information that has been stripped of
            personal identifiers is a powerful tool for clinical medicine and
            science. We refer to this kind of information as de-identified
            information. It can help accelerate understanding of genetic
            conditions, improve genetic testing, speed development of new
            treatments, and advance research that may one day lead to cures for
            a variety of diseases.
          </p>
        </div>
      </section>
      <section className="with-icon">
        <div className="icon">
          <img src="/assets/dna-cards.svg" alt="dna cards svg icon" />
        </div>
        <div className="text">
          <h2>Is this similar to ClinVar?</h2>
          <p>
            Invitae is the{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.ncbi.nlm.nih.gov/clinvar/docs/submitter_list/"
            >
              leading contributor
            </a>{" "}
            to ClinVar with 300K submissions covering more than 12K genes.
            Invitae submits a similar dataset to ClinVar approximately every 6
            months. It takes several months for ClinVar to process and post our
            submissions. This means the Invitae dataset on ClinVar is 7 to 9
            months behind our internal data. This tool will reduce the delay for
            many users and provide them with Invitae’s up to date
            classifications.
          </p>
        </div>
      </section>
    </main>
  );
}
