export const ENV = Object.freeze({
    AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
    AUTH0_AUDIENCE: process.env.AUTH0_AUDIENCE,
    AUTH0_ISSUER: process.env.AUTH0_DOMAIN,
})

export const AVAILABLE_AUTH0_PERMISSIONS = Object.freeze({
    ADVANCED: "read:advanced_dashboard",
});
