import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ENV } from "../config";

const LoginButton = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  return (
    <button
      onClick={() => {
        if (isAuthenticated) {
          logout({
            returnTo: window.location.origin
          });
        } else {
          loginWithRedirect({
            audience: ENV.AUTH0_AUDIENCE ? ENV.AUTH0_AUDIENCE: "https://localhost:8888"
          });
        }
      }}
    >
      {isAuthenticated ? "Sign out" : "Sign in"}
    </button>
  );
};

export default LoginButton;
