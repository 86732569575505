import React from "react";

export default function Footer(props) {
  return (
    <footer>
      <div>
        <div>
          <a target="_blank" href="https://www.invitae.com/privacy">
            Privacy policy
          </a>
          <a target="_blank" href="https://www.invitae.com/terms">
            Terms
          </a>
          <a target="_blank" href="https://www.invitae.com">
            Invitae.com
          </a>
        </div>
        <div>
          © {new Date().getFullYear()} Invitae corporation. All rights reserved.
        </div>
      </div>
    </footer>
  );
}
