import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, NavLink } from "react-router-dom";

import LoginButton from "./LoginButton";

export default function (props) {
  const { isAuthenticated } = useAuth0();
  let outreachLink;
  const outreachUrl = process.env.OUTREACH_URL ? process.env.OUTREACH_URL : "https://docs.google.com/forms/d/e/1FAIpQLScyKvp3jtW2mbVlQd58InRVU5DKb9OCFLNuUp2r_dCBKXh7Mg/viewform"
  if (isAuthenticated) {
    outreachLink = <a target="_blank" href={outreachUrl}>Outreach</a>
  }

  return (
    <header>
      <div>
        <a href="/" className="invitae-logo">
          Invitae Logo
        </a>
        <div>
          <Link to="/">Research</Link>
          <NavLink activeClassName="active" to="/looker">
            Home
          </NavLink>
          <NavLink activeClassName="active" to="/prevalence">
            Prevalence
          </NavLink>
          <NavLink activeClassName="active" to="/about">
            About
          </NavLink>
          <NavLink activeClassName="active" to="/privacy">
            Privacy
          </NavLink>
          {outreachLink}
        </div>
      </div>
      <div>
        <LoginButton />
      </div>
    </header>
  );
}
