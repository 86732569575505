import jwt from "jsonwebtoken";

export const getDashboardIdFromURL = (url) => {
    if (url.includes("/looker"))
        return process.env.EXPLORER_DASHBOARD_ID;
    else if (url.includes("/prevalence"))
        return process.env.PREVALENCE_DASHBOARD_ID;
    else
        return null;
}

export const getPayloadFromJWT = (token) => {
    var decoded = jwt.decode(token, { complete: true });
    if (decoded)
        return decoded.payload;
    else
        return null;
};

export const getHeaderFromJWT = (token) => {
    var decoded = jwt.decode(token, { complete: true });
    if (decoded)
        return decoded.header;
    else
        return null;
};

export const findPermissionInToken = (token, expectedPermission) => {
    var payload, indexOfPermission;

    payload = getPayloadFromJWT(token);

    try {
        indexOfPermission = payload.permissions.indexOf(expectedPermission);
    } catch (err) {
        console.log(err);
        return null;
    }

    if (indexOfPermission == null)
        return null;
    if (indexOfPermission >= 0)
        return true;
    else
        return false;
}