import React, { useEffect, useRef, useState } from "react";

export const DE_SERVICE_URL =
  "http://localhost:8888";
export const DE_SERVICE_EMBED = "invitae/embed";

export default function Frame(props) {
  const {
    children,
    queryFilters,
    dashboardId,
    dashboardType,
    srvUrl,
    style,
    authTokenCallback
  } = props;
  const [error, setError] = useState(null);
  const [src, setSrc] = useState(null);
  const forwardRef = useRef(null);

  /* fetch and set the iframeUrl src on mount */
  async function fetchUrl() {
    let lookerFilters = queryFilters || window.location.search;
    var url = `${srvUrl ||
      DE_SERVICE_URL}/${DE_SERVICE_EMBED}/${dashboardType}/${dashboardId}${lookerFilters}`;
    const headers = new Headers();

    let token = null;
    if (
      typeof authTokenCallback === "function" &&
      (token = authTokenCallback())
    ) {
      headers.set("Authorization", `Bearer ${token}`);
    }

    let src = await fetch(url, { headers })
      .then(handleIFrameSrcResponse)
      .catch(console.error);

    if (src) {
      setSrc(src);
    } else {
      setError("There was an error loading the embeddable Looker content.");
    }
  }
  useEffect(() => {
    fetchUrl();
  }, [dashboardId, queryFilters, dashboardType, srvUrl, authTokenCallback]);

  /* add `postMessage` listener on mount, and remove on unmount. */
  useEffect(() => {
    window.addEventListener("message", onMessage);

    return () => {
      window.removeEventListener("message", onMessage);
    };
  }, []);

  const frameProps = {
    forwardRef,
    dashboardId,
    dashboardType,
    src,
    style
  };

  return (
    <div id="de-frame">
      {(src && <InnerFrame {...frameProps} />) ||
        (error && <div className="error">{children || error}</div>) || (
          <div className="loading">loading...</div>
        )}
    </div>
  );
}

export function handleIFrameSrcResponse(r) {
  if (r.ok) {
    return r.json().then(json => json.iframeUrl);
  } else {
    return r
      .blob()
      .then(blob => blob.text())
      .then(err => {
        console.error(err);
        throw new Error(err);
      });
  }
}

/* listen for dashboard filter changes from Looker, and append filters as query params to url */
/* https://docs.looker.com/reference/embedding/embed-javascript-events */
export function onMessage(event) {
  if (event.source === document.getElementById("looker-frame").contentWindow) {
    let lookerFilters = lookerFiltersFromEvent(JSON.parse(event.data));
    if (lookerFilters) {
      window.history.replaceState(null, "", "?" + lookerFilters);
    }
  }
}

/* returns URL-encoded string of looker filters if the looker event contains new filter selections */
/* e.g. "Ethnicity=Caucasian%2CPacific%20Islander%2CJapanese%2CFilipino&Gender=Male" */
export function lookerFiltersFromEvent(eventData) {
  if (eventData.type != "dashboard:filters:changed") {
    return "";
  }
  const enc = encodeURIComponent;
  let filters = eventData.dashboard.dashboard_filters;
  var filterArgs = [];
  for (const [k, v] of Object.entries(filters)) {
    if (v != "") filterArgs.push(`${enc(k)}=${enc(v)}`);
  }
  return filterArgs.join('&');
}

export function InnerFrame(props) {
  const { forwardRef, dashboardId, dashboardType, src, style } = props;

  const styles = {
    border: 0,
    width: "100%",
    height: "calc(100vh - 65px - 95px)" // fix the header (65px) and footer (95px) on the page
  };

  return (
    <div>
      <iframe
        id='looker-frame'
        src={src}
        style={styles}
      />
    </div>
  );
}
