import React from "react";

export default function(props) {
  return (
    <main>
      <section>
        <h1 className="teal">Invitae privacy notice</h1>
      </section>
      <section className="with-icon">
        <div className="icon">
          <img src="/assets/shield-dna.svg" alt="shielded dna svg icon" />
        </div>
        <div className="text">
          <h2>When does Invitae share de-identified data?</h2>
          <p>
            We share de-identified data in specific ways that help advance
            medical care and the clinical practice of genetics. For example, we
            share de-identified data about genetic variants we observe with a
            few carefully selected public databases to advance the understanding
            of genetic information.
          </p>
        </div>
      </section>
      <section className="with-icon">
        <div className="icon">
          <img
            src="/assets/hand-sparkles.svg"
            alt="hand with sparkles svg icon"
          />
        </div>
        <div className="text">
          <h2>What are the benefits of sharing de-identified data?</h2>
          <p>
            Sharing de-identified genetic data is an essential component of the
            system by which laboratories assess and improve the quality of the
            genetic testing they provide. It can also significantly accelerate
            medical research for both individual patients and society as a
            whole.
          </p>
          <p>
            In addition to patients owning and controlling their genetic data,
            Invitae also believes that genetic information is more valuable when
            shared. We encourage patients to choose to share their de-identified
            genetic variants with the medical and scientific community to help
            accelerate our understanding of genetic conditions, improve genetic
            testing, find new therapies, and eventually prevent disease.
          </p>
        </div>
      </section>
      <section>
        <h2>Learn more</h2>
        <p>
          This page is not meant as a substitute for our Privacy Policy and
          Notice of Privacy Practices, both of which can be found at{" "}
          <a href="https://www.invitae.com/en/privacy/">
            https://www.invitae.com/en/privacy/
          </a>
          .
        </p>
      </section>
    </main>
  );
}
