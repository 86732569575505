// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/hero.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".hero {\n  align-items: center;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  display: flex;\n  height: 570px;\n  justify-content: center;\n  margin-top: calc(var(--grid-column) * -1);\n  position: relative;\n  width: 100%;\n}\n\n.hero div.text {\n  max-width: 440px;\n}\n\n.hero div.text > * {\n  margin-bottom: 20px;\n}\n", ""]);
// Exports
module.exports = exports;
