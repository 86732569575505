import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired
} from "@auth0/auth0-react";

import "./App.css";
import Components from "./components";
import Frame from "./frame";
import Pages from "./pages";

import { getDashboardIdFromURL } from "./utils";
import { ENV } from "./config";

export default function App() {
  const redirectCallback = appState => {
    if (appState && appState.returnTo) {
      window.location.href = appState.returnTo;
    } else {
      window.location.href = window.location.pathname;
    }
  };

  return (
    <Auth0Provider
      domain={ENV.AUTH0_DOMAIN ? ENV.AUTH0_DOMAIN : "invitae-dev-sandbox.auth0.com"}
      clientId={ENV.AUTH0_CLIENT_ID ? ENV.AUTH0_CLIENT_ID : "PapdeagFo3DQXXvik4yGucDIvBZ52zIS"}
      audience={ENV.AUTH0_AUDIENCE ? ENV.AUTH0_AUDIENCE : "https://localhost:8888"}
      issuer={ENV.AUTH0_ISSUER ? ENV.AUTH0_ISSUER : "https://invitae-dev-sandbox.auth0.com"}
      onRedirectCallback={redirectCallback}
      redirectUri={window.location.origin}
      useRefreshTokens={true}
    >
      <Router>
        <Components.HeaderNav />
        <Switch>
          <Route path="/about">
            <Pages.About />
          </Route>
          <Route path="/privacy">
            <Pages.Privacy />
          </Route>

          {/**
             * Remove these private routes when accessing the dashboards in a local environment
            */}

          <PrivateRoute component={DashboardLoader} path="/looker" />
          <PrivateRoute component={DashboardLoader} path="/prevalence" />

          {/** In tandem with removing the 'checkAuth0' and 'jwtAuthz' auth checks in the data-explorer-service
           * you can bypass the auth checks for local development
          <Route path="/looker">
            <Frame dashboardId={process.env.EXPLORER_DASHBOARD_ID} srvUrl={process.env.API_URL} dashboardType="lookerdashboard" />
          </Route>
          <Route path="/prevalence">
            <Frame dashboardId={process.env.PREVALENCE_DASHBOARD_ID} srvUrl={process.env.API_URL} dashboardType="lookerdashboard" />
          </Route>
          */}

          <Route path="/">
            <Pages.Home />
          </Route>
        </Switch>
        <Components.Footer />
      </Router>
    </Auth0Provider>
  );
}

function TemporarilyOutOfOrder() {
  return <div className="ooo">Data Explorer Dashboard... Coming Soon!</div>;
}

function DashboardLoader(props) {
  const { getAccessTokenSilently } = useAuth0();
  const [token, setToken] = useState(null);
  const dashboardId = getDashboardIdFromURL(window.location.href);

  useEffect(() => {
    (async () => {
      try {
        setToken(
          await getAccessTokenSilently({
            audience: ENV.AUTH0_AUDIENCE ? ENV.AUTH0_AUDIENCE : "https://localhost:8888"
          })
        );
      } catch (e) {
        console.error("Auth0 getAccessTokenSilently() error: ", e);
      }
    })();
  }, [getAccessTokenSilently, token]);

  if (token) {
    return (
      <Frame
        dashboardId={dashboardId != null ? dashboardId : process.env.EXPLORER_DASHBOARD_ID}
        dashboardType="lookerdashboard"
        srvUrl={process.env.API_URL}
        authTokenCallback={() => token}
      >
        <TemporarilyOutOfOrder />
      </Frame>
    );
  } else {
    return <div>loading dashboard</div>;
  }
}

function PrivateRoute({ component, ...args }) {
  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <div>redirecting to login</div>,
        returnTo: () => window.location.href
      })}
      {...args}
    />
  );
}
