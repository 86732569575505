import React from "react";
import { useHistory } from "react-router-dom";

import "./Home.css";

export default function(props) {
  const history = useHistory();

  return (
    <main>
      <section className="with-bg hero">
        <div className="grid-max-width">
          <div className="text">
            <h1>Advancing our understanding of genetic disease</h1>
            <p>
              Invitae believes knowledge is more valuable when shared and is
              committed to making its clinically observed variants publicly
              available.
            </p>
            <div>
              <button
                className="primary"
                onClick={() => history.push('/looker')}
              >
                Explore the tool
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="banner">
        <h1>
          The data in this tool is de-identified and aggregate. Please contact
          us if you have any questions: data-explorer@invitae.com
        </h1>
      </div>
    </main>
  );
}
